import Vue from 'vue'

const eBus = new Vue()

// Override the $emit method to add logging
const originalEmit = eBus.$emit
eBus.$emit = function(event, ...args) {
  console.log(`%cEvent emitted: ${event}`, 'color: green;', ...args)
  return originalEmit.apply(this, [event, ...args])
}

// Override the $on method to add logging
const originalOn = eBus.$on
eBus.$on = function(event, callback) {
  console.log(`%cEvent listener added: ${event}`, 'color: green;')
  return originalOn.apply(this, [event, callback])
}

export default eBus